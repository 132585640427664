import React, { useState, useEffect } from "react";
import logo from "../Assets/imgs/Logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import { HashLink } from "react-router-hash-link";
import GolbeImg from "../Assets/imgs/10-108245_globe-png-transparent.png";
const NavbarSec = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const onChangeLang = async (lang) => {
    let resp = await changeLanguage(lang);
    console.warn("resp", resp);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`header ${isScrolled ? "scrolled" : ""}`}>
        <div className="container">
          <div className="header-wraper">
            <a className="logo" href="https://www.instagram.com/cuizen.ca/">
              <img src={logo} alt="logo" />
            </a>
            <div className="nav-items">
              <ul className={`nav-links ${isMobileMenuOpen ? "active" : ""}`}>
                <div className="flexitem dis-mobile">
                  <div className="logo ">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="cros-icon" onClick={toggleMobileMenu}>
                    X
                  </div>
                </div>
                <li>
                  <Link to="/" className="nav-item">
                    {t("navbar.home")}
                  </Link>
                </li>
                <li>
                  {/* <Link to="/kitchen" className="nav-item">
                    {t("navbar.Kitchen")}
                  </Link> */}
                  <NavDropdown title={t("navbar.Kitchen")} className="dropDown">
                    <NavDropdown.Item>
                      <HashLink smooth className="anchor" to="/kitchen#1">
                        {t("kitchen.kitchenCabinetTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/kitchen#2">
                        {t("kitchen.kitchenCountertopsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/kitchen#3">
                        {t("kitchen.kitchenSinksTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/kitchen#4">
                        {t("kitchen.kitchenLightingTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/kitchen#5">
                        {t("kitchen.kitchenFlooringTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <HashLink smooth className="anchor" to="/kitchen#6">
                        {t("kitchen.kitchenBacksplashTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/kitchen#7">
                        {t("kitchen.kitchenHardwareTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
                <li>
                  {/* <Link to="/cabinetry" className="nav-item">
                    {t("navbar.Cabinetry")}
                  </Link> */}

                  <NavDropdown
                    title={t("navbar.Cabinetry")}
                    className="dropDown"
                  >
                    <NavDropdown.Item>
                      <HashLink smooth className="anchor" to="/cabinetry#1">
                        {t("cabinetry.cabinetsMontrealTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <HashLink smooth className="anchor" to="/cabinetry#2">
                        {t("cabinetry.wallCabinetsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#3">
                        {t("cabinetry.pantryCabinetsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#4">
                        {t("cabinetry.cabinetDoorsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#5">
                        {t("cabinetry.cabinetDrawersTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#6">
                        {t("cabinetry.customDressersTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#7">
                        {t("cabinetry.dressersTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#8">
                        {t("cabinetry.customizedEntertainmentUnitsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#9">
                        {t("cabinetry.tailoredClosetsMontrealTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#10">
                        {t("cabinetry.decorativeCabinetsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      {" "}
                      <HashLink smooth className="anchor" to="/cabinetry#11">
                        {t("cabinetry.chinaCabinetsTitle")}
                      </HashLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
                <li>
                  <Link to="/aboutus" className="nav-item">
                    {t("navbar.About")}
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="nav-item">
                    {t("navbar.Contact")}
                  </Link>
                </li>
                <NavDropdown
                  title={
                    <>
                      <img
                        src={GolbeImg}
                        alt="Globe"
                        style={{ width: "20px", height: "auto" }}
                      />
                    </>
                  }
                  className="dropDown"
                >
                  <NavDropdown.Item onClick={() => onChangeLang("fr")}>
                    <span style={{ marginLeft: "10px" }}>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                        alt="France Flag"
                        style={{ width: "20px", height: "auto" }}
                      />
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => onChangeLang("en")}>
                    <span style={{ marginLeft: "10px" }}>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg"
                        alt="England Flag"
                        style={{ width: "20px", height: "auto" }}
                      />
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
              </ul>
            </div>
            <div className="navlinks  dis-desk">
              <a href="https://www.facebook.com/profile.php?id=61557011210425">
                <i
                  className="fab fa-facebook-f"
                  style={{ color: "#316FF6" }}
                ></i>
              </a>
              <a href="https://www.instagram.com/cuizen.ca/">
                <i
                  className="fab fa-instagram"
                  style={{ color: "#E4405F" }}
                ></i>
              </a>
              {/* <a href="Facebook">
                <i
                  className="fab fa-linkedin-in"
                  style={{ color: "#0077B5" }}
                ></i>
              </a> */}
              {/* <a href="Facebook">
                <i className="fab fa-houzz"></i>
              </a> */}
            </div>
            <div className="menubar dis-mobile" onClick={toggleMobileMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="overlay" onClick={toggleMobileMenu}></div>
      )}
    </div>
  );
};

export default NavbarSec;
