import React from "react";
import AboutUs from "../component/AboutUs";
import OurServices from "../component/OurServices";
// import img1 from "../Assets/imgs/image8.png";
import img1 from "../Assets/imgs/cabinetry1.png";
import img2 from "../Assets/imgs/cabinetry2.png";
import img3 from "../Assets/imgs/cabinetry3.png";
import img4 from "../Assets/imgs/cabinetry4.png";
import img5 from "../Assets/imgs/cabinetry5.png";
import img6 from "../Assets/imgs/cabinetry6.png";
import img7 from "../Assets/imgs/Dresser.jpg";
import img8 from "../Assets/imgs/Customized TV, Wall, and Entertainment Units.jpg";
import img9 from "../Assets/imgs/China Cabinate.jpg";
import img10 from "../Assets/imgs/Exquisite Decorative Cabinets.jpg";



import BannerSec from "../component/BannerSec";
import { useTranslation } from "react-i18next";

const CabinetryPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerSec
        heading={t("cabinetry.title")}
        secondaryHeading={t("cabinetry.subText")}
      />
      <AboutUs
        id={1}
        title={t("cabinetry.cabinetsMontrealTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.cabinetsMontreal")}
        display={""}
        img={img1}
      />
      <OurServices
        id={2}
        title={t("cabinetry.wallCabinetsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.wallCabinets")}
        display={""}
        img={img2}
      />
      <AboutUs
        id={3}
        title={t("cabinetry.pantryCabinetsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.pantryCabinets")}
        display={""}
        img={img3}
      />
      <OurServices
        id={4}
        title={t("cabinetry.cabinetDoorsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.cabinetDoors")}
        display={""}
        img={img4}
      />
      <AboutUs
        id={5}
        title={t("cabinetry.cabinetDrawersTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.cabinetDrawers")}
        display={""}
        img={img5}
      />
      <OurServices
        id={6}
        title={t("cabinetry.customDressersTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.customDressers")}
        display={""}
        img={img6}
      />
      <AboutUs
        id={7}
        title={t("cabinetry.dressersTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.dressers")}
        display={""}
        img={img7}
      />
      <OurServices
        id={8}
        title={t("cabinetry.customizedEntertainmentUnitsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.customizedEntertainmentUnits")}
        display={""}
        img={img8}
      />
      <AboutUs
        id={9}
        title={t("cabinetry.tailoredClosetsMontrealTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.tailoredClosetsMontreal")}
        display={""}
        img={img9}
      />
      <OurServices
        id={10}
        title={t("cabinetry.decorativeCabinetsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.decorativeCabinets")}
        display={""}
        img={img10}
      />
      <AboutUs
        id={11}
        title={t("cabinetry.chinaCabinetsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("cabinetry.chinaCabinets")}
        display={""}
        img={img1}
      />
    </>
  );
};

export default CabinetryPage;
