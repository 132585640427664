import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../Style/AboutUs.css";

const OurServices = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const phoneNumber = "(514) 9678967";

  const handlePhoneNumberClick = () => {
    window.location.href = "tel:" + phoneNumber.replace(/\D/g, "");
  };
  return (
    <>
      <section
        className="about-us"
        style={{ backgroundColor: "#ffff" }}
        id={props.id}
      >
        <Container>
          <Row
            style={{
              flexDirection: isSmallScreen ? "     column-reverse" : "",
            }}
            className="about-us-row"
          >
            <Col xs={12} md={12} lg={5}>
              <div className="img-div2">
                <img
                  src={props.img}
                  alt=""
                  width={960}
                  height={604}
                  className="about-us-img"
                ></img>
              </div>
            </Col>
            <Col xs={12} md={12} lg={6} className="aboutus-col">
              <h2
                className="main-heading"
                data-aos={isSmallScreen ? "" : "fade-left"}
                data-aos-duration={isSmallScreen ? "" : "1500"}
              >
                {props.title}
              </h2>
              {props.desc.split("\n").map((val, index) => (
                <p
                  className={
                    index === props.desc.split("\n").length - 1
                      ? "p-with-border"
                      : "p-without-border"
                  }
                  key={index}
                  data-aos={isSmallScreen ? "" : "fade-left"}
                  data-aos-duration={isSmallScreen ? "" : "1500"}
                >
                  {val}
                </p>
              ))}
              <p
                className="P-heading"
                data-aos={isSmallScreen ? "" : "fade-left"}
                data-aos-duration={isSmallScreen ? "" : "1500"}
                style={{ paddingBottom: 0 }}
              >
                {props.secondHeading}
              </p>
              <p
                className="number"
                data-aos={isSmallScreen ? "" : "fade-left"}
                data-aos-duration={isSmallScreen ? "" : "1500"}
                onClick={handlePhoneNumberClick}
                style={{ cursor: "pointer" }}
              >
                (514) 9678967
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurServices;
