import React from "react";
import "../../Style/parallax.css";
import { useTranslation } from "react-i18next";

const Parallex = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="parallax-container">
        <div className="parallax">
          <div className="overlay"></div>
          <div className="parallax-content">
            <h1>{props.heading}</h1>
            <p>{props.subHeading}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Parallex;
