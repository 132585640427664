import React from "react";
import { Routes, Route } from "react-router-dom";
import BannerSec from "../component/BannerSec";
import HomePage from "../Pages/HomePage";
import AboutUspage from "../Pages/AboutUsPage";
import Footer from "../component/Footer";
import KitchenPage from "../Pages/KitchenPage";
import CabinetryPage from "../Pages/CabinetryPage";
import ContactUsPage from "../Pages/ContactUsPage";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUspage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/kitchen" element={<KitchenPage />} />
        <Route path="/cabinetry" element={<CabinetryPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default AllRoutes;
