import React from "react";
import NavbarSec from "../component/NavbarSec";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const BannerSec = (props) => {
  const phoneNumber = "(514) 9678967";

  const handlePhoneNumberClick = () => {
    window.location.href = "tel:" + phoneNumber.replace(/\D/g, "");
  };
  const { t } = useTranslation();
  return (
    <div>
      <NavbarSec />
      <div class="banner-section">
        <div class="baner-text">
          <div className="banner-head">{props.heading}</div>
          <div className="short-text">{props.secondaryHeading}</div>
          <span
            className="call-button"
            onClick={handlePhoneNumberClick}
            style={{ cursor: "pointer" }}
          >
            {t("aboutUs.call")} (514) 9678967
          </span>
        </div>
      </div>
    </div>
  );
};

export default BannerSec;
