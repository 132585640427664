import React from "react";
import AboutUs from "../component/AboutUs";
import OurServices from "../component/OurServices";
import img1 from "../Assets/imgs/image8.png";
import BannerSec from "../component/BannerSec";
import { useTranslation } from "react-i18next";
import Parallex from "../component/common/Parallex";

const HomePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerSec heading={t("home.title")} secondaryHeading={""} />
      <AboutUs
        title={t("home.card1Title")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("home.card1Desc")}
        display={""}
        img={img1}
      />
      <OurServices
        title={t("home.card2Title")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("home.card2Desc")}
        display={""}
        img={img1}
      />
      <Parallex
        heading={t("home.yourSatisfaction")}
        subHeading={t("home.yourSatisfactionDesc")}
      />
    </>
  );
};

export default HomePage;
