import React, { useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import img1 from "../Assets/imgs/image8.png";
import img2 from "../Assets/imgs/image4.png";
import img3 from "../Assets/imgs/image3.png";
import "../Style/OurProduct.css";

const OurProduct = (props) => {
  const [textslice, setTextSlice] = useState(true);
  const [clickIndex, setClickIndex] = useState(0);
  const textSlice = (text, index) => {
    if (textslice) {
      return text.slice(0, 150);
    } else {
      if (index === clickIndex) {
        return text;
      } else {
        return text.slice(0, 150);
      }
    }
  };
  return (
    <>
      <section className="our-product">
        <Container>
          <h2 className="main-heading">{props.title}</h2>
          <p>{props.decs}</p>
          <Row className="card-grid">
            {props.OurServices.map((item, index) => (
              <Col xs={12} md={4} lg={4}>
                <Card
                  style={{ width: "100%" }}
                  // data-aos="fade-down"
                  // data-aos-easing="linear"
                  // data-aos-duration="1000"
                >
                  <Card.Img
                    variant="top"
                    src={index === 0 ? img1 : index === 1 ? img2 : img3}
                    height={253}
                  />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>

                    <Card.Text>{textSlice(item.desc, index)}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          {/* <span
            className="link-readmore"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            Explore More
          </span> */}
        </Container>
      </section>
    </>
  );
};

export default OurProduct;
