import React from "react";
import BannerSec from "../component/BannerSec";
import ContactUsForm from "../component/ContactUsFrom";
import { useTranslation } from "react-i18next";

const ContactUsPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerSec
        heading={t("contactUs.title")}
        secondaryHeading={t("contactUs.subTitle")}
      />
      <ContactUsForm />
    </>
  );
};

export default ContactUsPage;
