import React, { useState } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import "../Style/ContactUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  faToolbox,
  faEnvelope,
  faMapMarkerAlt,
  faTools,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
const phoneNumber = "(514) 9678967";
function ContactUsForm() {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const handlePhoneNumberClick = () => {
    window.location.href = "tel:" + phoneNumber.replace(/\D/g, "");
  };
  return (
    <>
      <div style={{ backgroundColor: "#F4F8FD", padding: 100 }}>
        <Container style={{ width: "93%" }}>
          <section>
            <div>
              <h3>
                <span>{t("contactUs.getInTouch")}</span>
              </h3>
              <div
                style={{
                  borderBottom: "4px solid #808080",
                  width: 63,
                }}
              />
              <p className="contactText">
                {t("contactUs.getInTouchDesc1")}
                <br /> {t("contactUs.getInTouchDesc2")}
              </p>
            </div>
          </section>
        </Container>
      </div>
      <div style={{ backgroundColor: "lightgrey" }}>
        <Container className="contactContainer">
          <Row>
            <Col xs={12} md={6} style={{ padding: "40px" }}>
              <h3 className="contactH3">{t("contactUs.sendUsAMessage")}</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        required
                        type="text"
                        placeholder={t("contactUs.name")}
                        className="contactField"
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        Please provide a name.
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicEmail">
                      {/* <Form.Label>Email address</Form.Label> */}
                      <Form.Control
                        required
                        type="email"
                        placeholder={t("contactUs.email")}
                        className="contactField"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicPhone">
                      {/* <Form.Label>Phone Number</Form.Label> */}
                      <Form.Control
                        type="tel"
                        placeholder={t("contactUs.phone")}
                        className="contactField"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicCompany">
                      <Form.Control
                        type="text"
                        placeholder={t("contactUs.address")}
                        className="contactField"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicCompany">
                      <Form.Control
                        type="text"
                        placeholder={t("contactUs.company")}
                        className="contactField"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group
                      controlId="formFile"
                      style={{ marginTop: "23px" }}
                    >
                      <Form.Control type="file" />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formBasicMessage">
                  {/* <Form.Label>Message</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t("contactUs.message")}
                    className="contactField"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="contactFormButton"
                >
                  {t("contactUs.sendMessage")}
                </Button>
              </Form>
            </Col>
            <Col xs={12} md={6} style={{ padding: "40px" }}>
              <h3 className="contactH3">{t("contactUs.contactInfo")}</h3>
              <Row>
                <Col xs={12} md={6}>
                  <div className="contactInfoMainDiv">
                    <div className="contactIconContainer">
                      <FontAwesomeIcon
                        className="contactIcon"
                        icon={faToolbox}
                      />
                    </div>
                    <div className="contactSubContainer">
                      <h5 className="contactsubHeading">
                        {t("contactUs.businessName")}
                      </h5>
                      <div className="contactsubDesc">Cuizen Cabinet</div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="contactInfoMainDiv">
                    <div className="contactIconContainer">
                      <FontAwesomeIcon
                        className="contactIcon"
                        icon={faEnvelope}
                      />
                    </div>
                    <div className="contactSubContainer">
                      <h5 className="contactsubHeading">
                        {t("contactUs.emailWithoutSterick")}
                      </h5>
                      <div className="contactsubDesc">info@cuizen.ca</div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="contactInfoMainDiv">
                    <div className="contactIconContainer">
                      <FontAwesomeIcon
                        className="contactIcon"
                        icon={faMapMarkerAlt}
                      />
                    </div>
                    <div className="contactSubContainer">
                      <h5 className="contactsubHeading">
                        {t("contactUs.addressWithoutSterick")}
                      </h5>
                      <div className="contactsubDesc">
                        9697 st Laurent suite 101 Quebec H3L-2N1
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="contactInfoMainDiv">
                    <div className="contactIconContainer">
                      <FontAwesomeIcon className="contactIcon" icon={faPhone} />
                    </div>
                    <div className="contactSubContainer">
                      <h5 className="contactsubHeading">
                        {t("contactUs.callUs")}
                      </h5>
                      <div
                        className="contactsubDesc"
                        onClick={handlePhoneNumberClick}
                        style={{ cursor: "pointer" }}
                      >
                        (514) 9678967
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactUsForm;
