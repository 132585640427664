import React from "react";
import OurServices from "../component/OurServices";
import img1 from "../Assets/imgs/image8.png";
import Parallex from "../component/common/Parallex";
import OurProduct from "../component/OurProduct";
import services from "../utils/Ourservices.json";
import BannerSec from "../component/BannerSec";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <BannerSec
        heading={t("aboutUs.title")}
        secondaryHeading={t("aboutUs.subTitle")}
      />
      <OurServices
        title={t("aboutUs.ourStory")}
        secondHeading={t("aboutUs.craftingTailoredSolutions")}
        desc={t("aboutUs.story")}
        display={"none"}
        img={img1}
      />
      <OurProduct
        title={t("aboutUs.whatWeOffer")}
        OurServices={[
          {
            title: t("aboutUs.offer1"),
            desc: t("aboutUs.desc1"),
          },
          {
            title: t("aboutUs.offer2"),
            desc: t("aboutUs.desc2"),
          },
          {
            title: t("aboutUs.offer3"),
            desc: t("aboutUs.desc3"),
          },
        ]}
        decs={t("aboutUs.offer")}
      />
      <Parallex
        heading={t("aboutUs.bestServiceInIndustry​")}
        subHeading={t("aboutUs.serviceDesc")}
      />
    </>
  );
};

export default AboutUsPage;
