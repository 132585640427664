import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Style/Footer.css";
import logo from "../Assets/imgs/Logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToolbox,
  faLocationDot,
  faPhone,
  faEnvelope,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faHouzz,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const phoneNumber = "(514) 9678967";

  const handlePhoneNumberClick = () => {
    window.location.href = "tel:" + phoneNumber.replace(/\D/g, "");
  };
  return (
    <div>
      <Container className="footerContainer">
        <Row className="footerRow">
          <Col xs={12} sm={6} md={6} lg={3}>
            <a className="logo" href="https://www.instagram.com/cuizen.ca/">
              <img className="FooterLogo" src={logo} alt="logo" />
            </a>
            <div className="foterLogoDesc">
              {t("footer.desc1")}
              <br /> {t("footer.desc2")}
              <br /> {t("footer.desc3")}
            </div>
            <div className="footerSocialIconContainer">
              <a href="https://www.facebook.com/profile.php?id=61557011210425">
                <FontAwesomeIcon
                  className="footerSocialIcon"
                  icon={faFacebookF}
                />
              </a>
              <a href="https://www.instagram.com/cuizen.ca/">
                <FontAwesomeIcon
                  className="footerSocialIcon"
                  icon={faInstagram}
                />
              </a>
              {/* <FontAwesomeIcon className="footerSocialIcon" icon={faLinkedin} />
              <FontAwesomeIcon className="footerSocialIcon" icon={faHouzz} /> */}
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <h4 className="footerHeading">{t("footer.ourCompany")}</h4>
            <ul className="footerUl">
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faAngleRight} />
                <Link to="/aboutus">
                  <span className="footerspan">{t("navbar.About")}</span>
                </Link>
              </li>
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faAngleRight} />
                <Link to="/contactus">
                  <span className="footerspan">{t("navbar.Contact")}</span>
                </Link>
              </li>
              {/* <li>
                <FontAwesomeIcon className="footerIcon" icon={faAngleRight} />
                <Link to="/">
                  <span className="footerspan">{t("footer.gallery")}</span>
                </Link>
              </li> */}
            </ul>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <h4 className="footerHeading">{t("footer.ourServices")}</h4>
            <ul className="footerUl">
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faAngleRight} />
                <Link to="/kitchen">
                  <span className="footerspan">{t("navbar.Kitchen")}</span>
                </Link>
              </li>
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faAngleRight} />
                <Link to="/cabinetry">
                  <span className="footerspan">{t("navbar.Cabinetry")}</span>
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <h4 className="footerHeading">{t("footer.contactDetails")}</h4>
            <ul className="footerUl">
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faToolbox} />
                <span className="footerspan">Cuizen Cabinets</span>
              </li>
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faLocationDot} />
                <span className="footerspan">
                  9697 st Laurent suite 101 Quebec H3L-2N1
                </span>
              </li>
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faPhone} />
                <span
                  className="footerspan"
                  onClick={handlePhoneNumberClick}
                  style={{ cursor: "pointer" }}
                >
                  (514) 9678967
                </span>
              </li>
              <li>
                <FontAwesomeIcon className="footerIcon" icon={faEnvelope} />
                <span className="footerspan">info@cuizen.ca</span>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="Copyright">{t("footer.copyright")}</div>
      </Container>
    </div>
  );
};

export default Footer;
