import React from "react";
import AboutUs from "../component/AboutUs";
import OurServices from "../component/OurServices";
import img1 from "../Assets/imgs/image8.png";
import img2 from "../Assets/imgs/image3.png";
import img3 from "../Assets/imgs/image4.png";
import img4 from "../Assets/imgs/image5.png";
import img5 from "../Assets/imgs/image7.png";
import img6 from "../Assets/imgs/image2.png";
import img7 from "../Assets/imgs/image9.png";
import BannerSec from "../component/BannerSec";
import { useTranslation } from "react-i18next";

const KitchenPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <BannerSec
        heading={t("kitchen.title")}
        secondaryHeading={t("kitchen.subText")}
      />
      <AboutUs
        id={1}
        title={t("kitchen.kitchenCabinetTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenCabinet")}
        display={""}
        img={img1}
      />
      <OurServices
        id={2}
        title={t("kitchen.kitchenCountertopsTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenCounterparts")}
        display={""}
        img={img2}
      />
      <AboutUs
        id={3}
        title={t("kitchen.kitchenSinksTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenSinks")}
        display={""}
        img={img3}
      />
      <OurServices
        id={4}
        title={t("kitchen.kitchenLightingTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenLighting")}
        display={""}
        img={img4}
      />
      <AboutUs
        id={5}
        title={t("kitchen.kitchenFlooringTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenFlooring")}
        display={""}
        img={img5}
      />
      <OurServices
        id={6}
        title={t("kitchen.kitchenBacksplashTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenBacksplash")}
        display={""}
        img={img6}
      />
      <AboutUs
        id={7}
        title={t("kitchen.kitchenHardwareTitle")}
        secondHeading={t("home.getEstimateToday")}
        desc={t("kitchen.kitchenHardware")}
        display={""}
        img={img7}
      />
    </>
  );
};

export default KitchenPage;
